import React, { memo } from "react";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const SEO = ({ description, lang, meta, title, siteMetadata }) => {
  const desc = description || siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: desc
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: desc
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        // {
        //   name: `twitter:creator`,
        //   content: author
        // },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: desc
        }
      ]
        .concat(meta)
        .filter(({ content }) => !!content)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: []
};

const SeoWithData = props => {
  const siteMetadata = useSiteMetadata();
  return <SEO {...props} siteMetadata={siteMetadata} />;
};

export default memo(SeoWithData);
