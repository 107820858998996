import React from "react";
import SVGIcon from "./svg-icon";

const FacebookIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      d="M27.03 14.524c1.43-1.278 3.464-1.71 5.382-1.764 2.029-.017 4.057-.007 6.085-.006.008 2.01.009 4.022 0 6.032-1.312-.002-2.624.003-3.935-.002-.831-.049-1.685.543-1.836 1.318-.02 1.345-.007 2.691-.006 4.037 1.923.007 3.846-.003 5.769.004-.141 1.946-.398 3.884-.7 5.813-1.698.015-3.398-.001-5.096.009-.015 5.74.008 11.48-.012 17.22-2.523.01-5.047-.004-7.57.007-.048-5.741.004-11.485-.026-17.227-1.231-.012-2.463.009-3.693-.01.004-1.93.001-3.859.002-5.788 1.23-.014 2.46.003 3.69-.008.038-1.876-.037-3.755.04-5.629.123-1.485.725-2.98 1.907-4.006z"
    />
  </SVGIcon>
);

export default FacebookIcon;
