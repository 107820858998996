import styled from "@emotion/styled";
import { cleanList } from "../styles/shared";
import { rem } from "../styles/utils";

export const SocialList = styled.ul`
  ${cleanList};
  display: flex;
`;

export const SocialItem = styled.li`
  margin-left: ${rem(4)};

  &:first-of-type(li) {
    margin-left: 0;
  }
`;

export const SocialLink = styled.a`
  display: block;
`;
