import { css } from "@emotion/core";

export const baseFont = "CircularStd, sans-serif";

export const fontWeights = {
  black: 800,
  bold: 600,
  medium: 500,
  book: 400
};

export const rem = px => `${px / 16}rem`;

export const fontSize = (px, lineHeight) => {
  return css`
    font-size: ${rem(px)};
    line-height: ${(lineHeight && lineHeight / px) || "1.2"};
  `;
};

export const mobileBreak = css => {
  return css`
    @media (min-width: ${rem(650)}) {
      ${css}
    }
  `;
};

export const maxWidthpx = 1160;
export const maxWidth = rem(1160);

export const colors = {
  white: "#fff",
  black: "#000",
  darkGray: "#555555",
  mint: "#EDFFFC",
  aqua: "#6DFFEC",
  orange: "#FF5C39",
  orangeLight: "#F99D25"
};

export const sgdColors = [
  "#EB1C2D",
  "#D3A029",
  "#269B48",
  "#C31E33",
  "#EF402C",
  "#00AED9",
  "#FDB713",
  "#8F1838",
  "#F36D25",
  "#E11384",
  "#F99D25",
  "#CF8D2A",
  "#47773E",
  "#017DBC",
  "#3EB04A",
  "#02558B",
  "#183667"
];

export const visuallyHidden = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

export const hexToRGBA = (hex, a = 1) => {
  const value = parseInt(hex, 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return `rgba(${r},${g},${b},${a})`;
};
