import React from "react";
import SVGIcon from "./svg-icon";

const InstagramIcon = props => (
  <SVGIcon {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M29.993 9.013c-5.701 0-6.416.024-8.655.127-2.235.102-3.76.456-5.096.975a10.29 10.29 0 0 0-3.718 2.422 10.29 10.29 0 0 0-2.422 3.718c-.519 1.336-.874 2.862-.976 5.096C9.024 23.591 9 24.305 9 30.007s.024 6.416.126 8.655c.102 2.235.457 3.76.976 5.096a10.29 10.29 0 0 0 2.422 3.718 10.291 10.291 0 0 0 3.718 2.422c1.335.519 2.861.874 5.096.976 2.239.102 2.954.126 8.655.126 5.702 0 6.417-.024 8.656-.126 2.234-.102 3.76-.457 5.096-.976a10.29 10.29 0 0 0 3.718-2.422 10.29 10.29 0 0 0 2.422-3.718c.519-1.335.874-2.861.975-5.096.103-2.239.127-2.954.127-8.655 0-5.702-.024-6.417-.127-8.656-.101-2.234-.456-3.76-.975-5.096a10.29 10.29 0 0 0-2.422-3.718 10.29 10.29 0 0 0-3.718-2.422c-1.336-.519-2.862-.873-5.096-.975-2.24-.103-2.954-.127-8.656-.127m0 3.783c5.606 0 6.27.021 8.483.122 2.047.094 3.159.436 3.899.723.98.38 1.679.836 2.414 1.57.734.735 1.189 1.434 1.57 2.414.287.74.63 1.852.723 3.899.1 2.213.122 2.877.122 8.483 0 5.605-.021 6.27-.122 8.483-.094 2.047-.436 3.158-.723 3.898-.381.98-.836 1.68-1.57 2.414-.735.734-1.434 1.19-2.414 1.57-.74.288-1.852.63-3.899.723-2.213.101-2.877.122-8.483.122s-6.27-.021-8.483-.122c-2.047-.093-3.158-.435-3.898-.723-.98-.38-1.68-.836-2.414-1.57-.734-.735-1.19-1.434-1.57-2.414-.288-.74-.63-1.851-.723-3.898-.101-2.214-.122-2.878-.122-8.483 0-5.606.021-6.27.122-8.483.093-2.047.435-3.159.723-3.899.38-.98.836-1.679 1.57-2.413.735-.735 1.434-1.19 2.414-1.57.74-.288 1.851-.63 3.898-.724 2.214-.1 2.878-.122 8.483-.122" />
      <path d="M30 36.915a6.915 6.915 0 1 1 0-13.83 6.915 6.915 0 0 1 0 13.83m0-17.567c-5.883 0-10.652 4.769-10.652 10.652 0 5.883 4.769 10.652 10.652 10.652 5.883 0 10.652-4.769 10.652-10.652 0-5.883-4.769-10.652-10.652-10.652M43.696 18.74a2.435 2.435 0 1 1-4.87 0 2.435 2.435 0 0 1 4.87 0" />
    </g>
  </SVGIcon>
);

export default InstagramIcon;
