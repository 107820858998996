import React from "react";

import SEO from "../components/seo";

import NormalizeCSS from "../styles/normalize";
import BaseCSS from "../styles/global";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ seo, title, children }) => (
  <>
    <NormalizeCSS />
    <BaseCSS />
    <SEO title={title} {...seo} />
    <Header />
    <main>{children}</main>
    <Footer />
  </>
);

Layout.defaultProps = {
  seo: {}
};

export default Layout;
