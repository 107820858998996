import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { fontSize, fontWeights, rem, colors } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Link } from "gatsby";

const { black, white, mint } = colors;

const desktopRow = css`
  @media (${desktop}) {
    display: flex;
  }
`;

export const Nav = styled.nav`
  margin-left: auto;
  ${desktopRow};
`;

export const NavList = styled.ul`
  margin: auto 0;
  padding: 0;
  list-style: none;
  ${desktopRow};
`;

export const NavCountDown = styled.span`
  display: flex;
  align-items: center;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.medium};
  margin: auto ${rem(20)};

  @media (${desktop}) {
    justify-content: center;
  }
`;

export const NavDays = styled.span`
  display: inline-block;
  padding-left: ${rem(8)};
  ${fontSize(20, 20)};
  font-weight: ${fontWeights.black};
  position: relative;
  top: ${rem(-2)};
`;

export const NavLink = styled(Link)`
  display: inline-block;
  padding: ${rem(5)} ${rem(16)};
  border-radius: ${rem(30)};
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;

  &.active {
    background-color: ${mint};
  }
`;

export const NavBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(20)};
  background: ${black};
  margin-left: auto;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
  color: ${white};

  &:hover,
  &:active,
  &:focus {
    color: ${white};
  }
`;
