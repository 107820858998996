import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { cleanList } from "../styles/shared";
import { desktop } from "../styles/breakpoints";
import {
  fontSize,
  fontWeights,
  colors,
  rem,
  maxWidthpx
} from "../styles/utils";

const { black, white, orange } = colors;

export const addressLine = styled.span`
  display: block;
  margin-bottom: ${rem(10)};
`;

export const footerTxt = css`
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
`;

export const footerLegal = css`
  ${fontSize(14, 20)};
  font-style: normal;
  font-weight: ${fontWeights.book};
`;

export const footerAddress = css`
  ${fontSize(14, 24)};
  font-style: normal;
  font-weight: ${fontWeights.book};
`;

export const footerHighlight = css`
  ${fontSize(30, 40)};
  font-weight: ${fontWeights.book};
  color: ${orange};
  margin: 0;
`;

export const footerLegalLinks = css`
  ${cleanList};
  display: flex;
`;

export const footerSpaceBot = css`
  margin-top: 0;
  margin-bottom: ${rem(20)};
`;

export const Footer = styled.div`
  background: ${black};
  color: ${white};
  padding: ${rem(140)} 0 ${rem(40)};
`;

const colWidth = ({ small, large }) => css`
  @media (${desktop}) {
    ${small && `width:${(260 / maxWidthpx) * 100}%;`};
    ${large && `width:${(460 / maxWidthpx) * 100}%;`};
  }
`;

export const FooterCol = styled.div(
  props => css`
    ${colWidth(props)};
    ${props.zeroMargin && `margin: 0`};
    ${props.gutterLeft && `margin-left:${rem(18)}`};
    ${props.pushLeft && `margin-left: auto;`}
  `
);

export const FooterRow = styled.div(
  ({ bot }) => css`
    @media (${desktop}) {
      display: flex;
      ${bot && `align-items: flex-end; margin-top: ${rem(60)};`};
    }
  `
);

export const logo = css`
  margin-bottom: ${rem(40)};
`;
