import styled from "@emotion/styled";
// import { css } from "@emotion/core";
import { rem, fontSize } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Container } from "../styles/shared";

export const HeaderBox = styled(Container)`
  @media (${desktop}) {
    display: flex;
    align-items: stretch;
  }
`;

export const HeaderTitle = styled.h1`
  ${fontSize(20, 20)};
  padding: ${rem(10)} 0;
  margin: ${rem(12)} 0;
`;
