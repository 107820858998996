import React, { memo } from "react";
import FacebookIcon from "./facebook-icon";
import InstagramIcon from "./instagram-icon";
import TwitterIcon from "./twitter-icon";

const Icon = ({ icon, ...props }) => (
  <>
    {icon === "facebook" && <FacebookIcon {...props} />}
    {icon === "twitter" && <InstagramIcon {...props} />}
    {icon === "instagram" && <TwitterIcon {...props} />}
  </>
);

export default memo(Icon);

export { FacebookIcon, InstagramIcon, TwitterIcon };
