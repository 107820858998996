import React from "react";
import { Link } from "gatsby";

import { useNavData } from "../hooks/use-nav-data";
import SocialIcons from "./social-icons";
import NFTELogo from "../images/nfte-logo.svg";

import * as SS from "../styles/shared";
import * as S from "./footer.styles";

const Footer = ({ footerLinks, legalLinks, socialLinks }) => (
  <S.Footer>
    <SS.Container>
      <S.FooterRow>
        <S.FooterCol large>
          <img css={S.logo} src={NFTELogo} alt="NFTE" />
          <h2 css={[S.footerTxt, S.footerSpaceBot]}>
            World Series of Innovation
          </h2>
          <p css={S.footerHighlight}>
            We put the power of problem-solving in your hands
          </p>
        </S.FooterCol>

        <S.FooterCol pushLeft small>
          <ul css={SS.cleanList}>
            {footerLinks.map(({ label, url }, idx) => (
              <li css={S.footerSpaceBot} key={idx}>
                <Link css={S.footerTxt} to={url}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </S.FooterCol>

        <S.FooterCol gutterLeft small as="section">
          <h2 css={[S.footerTxt, S.footerSpaceBot]}>Contact Us</h2>
          <address css={S.footerLegal}>
            <S.addressLine css={S.footerAddress}>
              120 Wall Street, Suite 1801
              <br />
              New York, NY 10005
            </S.addressLine>
            <S.addressLine>+1 212 232 3333</S.addressLine>
            <a css={SS.orange} href="mailto:wsi@nfte.com">
              wsi@nfte.com
            </a>
          </address>
        </S.FooterCol>
      </S.FooterRow>

      <S.FooterRow bot>
        <S.FooterCol css={S.footerLegal} zeroMargin large as="p">
          © {new Date().getFullYear()} NFTE, Inc. All rights reserved worldwide.
        </S.FooterCol>

        <S.FooterCol css={S.footerLegalLinks} pushLeft small as="ul">
          {legalLinks.map(({ label, url }, idx) => (
            <li key={idx}>
              {idx !== 0 && `\u00a0`}
              <Link css={S.footerTxt} to={url}>
                {label}
              </Link>
              {idx === 0 && `\u00a0|`}
            </li>
          ))}
        </S.FooterCol>

        <S.FooterCol css={SS.cleanList} gutterLeft small>
          <SocialIcons links={socialLinks} />
        </S.FooterCol>
      </S.FooterRow>
    </SS.Container>
  </S.Footer>
);

export default () => {
  const data = useNavData();
  return <Footer {...data} />;
};
