import React from "react";
import { Link } from "gatsby";

import { useSiteMetadata } from "../hooks/use-site-metadata";
import * as S from "./header.styles";
import Nav from "./nav";

const Header = ({ siteTitle }) => (
  <S.HeaderBox>
    <S.HeaderTitle>
      <Link to="/">{siteTitle}</Link>
    </S.HeaderTitle>
    <Nav />
  </S.HeaderBox>
);

Header.defaultProps = {
  siteTitle: ``
};

export default () => {
  const { shortName } = useSiteMetadata();
  return <Header siteTitle={shortName} />;
};
