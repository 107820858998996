import React from "react";
import { useNavData } from "../hooks/use-nav-data";

import * as S from "./nav.styles";

const Nav = ({ links, skildLogin }) => (
  <>
    <S.Nav>
      <S.NavList>
        {links.map(({ label, url }, idx) => (
          <li key={idx}>
            <S.NavLink activeClassName="active" to={url}>
              {label}
            </S.NavLink>
          </li>
        ))}
      </S.NavList>
      <S.NavCountDown>
        Days left <S.NavDays>12</S.NavDays>
      </S.NavCountDown>
      <S.NavBtn href={skildLogin}>Get Started</S.NavBtn>
    </S.Nav>
  </>
);

export default () => {
  const data = useNavData();
  return <Nav {...data} />;
};
