import styled from "@emotion/styled";
import { Container } from "../styles/shared";
import { rem, colors, fontSize, fontWeights } from "../styles/utils";

const { mint } = colors;

export const PageBody = styled.div`
  background-color: ${mint};
  overflow: hidden;
`;

export const PageContent = styled(Container)`
  ${fontSize(18, 28)};
  font-weight: ${fontWeights.book};
  max-width: ${rem(840)};
  padding-top: ${rem(80)};
  padding-bottom: ${rem(130)};
`;

export const PageHeader = styled(Container)`
  max-width: ${rem(840)};
  min-height: ${rem(300)};
  padding-top: ${rem(50)};
  padding-bottom: ${rem(120)};
  text-align: center;
`;

export const PageCat = styled.small`
  margin: 0;
  margin-bottom: ${rem(16)};
  ${fontSize(20, 28)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
`;

export const PageTitle = styled.h1`
  margin: 0;
  ${fontSize(36, 50)};
  font-weight: ${fontWeights.medium};
`;
