import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { rem, maxWidth, maxWidthpx, colors, fontWeights } from "./utils";

export const clipBox = css`
  overflow: hidden;
`;

export const mobileGutter = css`
  padding-left: ${rem(30)};
  padding-right: ${rem(30)};

  @media (min-width: ${rem(maxWidthpx + 60)}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Container = styled.div`
  max-width: ${maxWidth};
  ${mobileGutter};
  margin: auto;
`;

export const H1 = styled.h1`
  font-size: ${rem(22)};
  font-weight: 600;
`;

export const H2 = styled.h2`
  font-size: ${rem(18)};
  font-weight: 600;
`;

export const H3 = styled.h3`
  font-size: ${rem(16)};
  font-weight: 600;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const cleanList = css`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const bold = css`
  font-weight: ${fontWeights.bold};
`;

const circleProps = ({ size = 20, color = colors.orangeLight }) => css`
  width: ${rem(size)};
  height: ${rem(size)};
  background-color: ${color};
`;

export const Circle = styled.div`
  display: block;
  ${circleProps};
  border-radius: 100%;
`;

export const orange = css`
  color: ${colors.orange};
`;
