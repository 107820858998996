import React from "react";

import * as S from "./page.styles";

const PageLegal = ({ pageCat, title, html }) => (
  <S.PageBody as="article">
    <S.PageHeader as="header">
      <h1 css={{ margin: 0 }}>
        <S.PageCat>{pageCat}</S.PageCat>
        <S.PageTitle>{title}</S.PageTitle>
      </h1>
    </S.PageHeader>
    {html && <S.PageContent dangerouslySetInnerHTML={{ __html: html }} />}
  </S.PageBody>
);

PageLegal.defaultProps = {
  pageCat: "Legal"
};

export default PageLegal;
