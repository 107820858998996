import { useStaticQuery, graphql } from "gatsby";

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        shortName
        description
      }
    }
  }
`;

export const useSiteMetadata = () => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(siteMetaQuery);
  return siteMetadata;
};
