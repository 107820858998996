export const useNavData = () => {
  return {
    links: [
      {
        label: "Home",
        url: "/"
      },
      {
        label: "Challenges",
        url: "/challenges"
      },
      {
        label: "How to compete",
        url: "/how-to-compete"
      }
    ],
    footerLinks: [
      {
        label: "Challenges",
        url: "/challenges"
      },
      {
        label: "How to compete",
        url: "/how-to-compete"
      },
      {
        label: "About",
        url: "/about"
      },
      {
        label: "Sponsors",
        url: "/sponsors"
      }
    ],
    legalLinks: [
      {
        label: "Privacy",
        url: "/privacy-policy"
      },
      {
        label: "Legal",
        url: "/privacy-policy"
      }
    ],
    socialLinks: [
      {
        icon: "instagram",
        link: "#instagram"
      },
      {
        icon: "facebook",
        link: "#facebook"
      },
      {
        icon: "linkedIn",
        link: "#linkedIn"
      },
      {
        icon: "twitter",
        link: "#twitter"
      }
    ],
    skildLogin: "https://wsi.skild.com/skild2/wsi/loginPage.action"
  };
};
