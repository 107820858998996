import React from "react";
import SVGIcon from "./svg-icon";

const TwitterIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M47.014 22.477c.015.323.023.653.023.975C47.037 33.465 38.985 45 24.268 45c-4.517 0-8.733-1.252-12.268-3.397.618.067 1.26.097 1.902.097 3.757 0 7.196-1.208 9.946-3.24-3.503-.06-6.459-2.25-7.481-5.258.491.083.99.135 1.506.135.729 0 1.442-.09 2.108-.262-3.662-.705-6.428-3.758-6.428-7.433v-.09c1.086.563 2.322.9 3.63.945-2.14-1.365-3.558-3.682-3.558-6.307 0-1.388.396-2.685 1.085-3.803 3.947 4.583 9.844 7.59 16.493 7.905a6.812 6.812 0 0 1-.206-1.717c0-4.185 3.59-7.575 7.996-7.575 2.307 0 4.39.922 5.85 2.385a16.638 16.638 0 0 0 5.08-1.83c-.603 1.77-1.871 3.255-3.52 4.185A16.766 16.766 0 0 0 51 18.555a15.697 15.697 0 0 1-3.994 3.922z"
    />
  </SVGIcon>
);

export default TwitterIcon;
